import { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom'
import QuantityBtn from './QuantityBtn';
import Title from './Title';

export default function ProductDetail() {
    let params = useParams();
    let [product, setProduct] = useState(null);
    
    useEffect(() => {
        fetch("https://hoyinleung.github.io/demoapi/react-basic-product.json") // async call
        .then(res => res.json())
        .then(data => {
            setProduct(data.find((product) => product.id === parseInt(params.id)))
            console.log(product)
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
        {
            product &&
            <div className="productDetail">
                <Title title={"Product Details for " + product.name} />

                <table width="100%">
                    <tbody>
                        <tr>
                            <td align="right">
                                <img src={process.env.PUBLIC_URL + "/images/" + product.image} width="400px" alt={product.name}/>
                            </td>
                            <td width="45%" padding="10">
                                <p>Name: {product.name}</p>
                                <p>Price: ${product.price}</p>
                                <p>Description: {product.description}</p><br />
                                <QuantityBtn productInfo={product}/>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        }
        
            <Link to="/">
                <div className="backToProductListBtn">↩️ Back to product list</div>
            </Link>
        </div>
    )
}
