import {useState, useContext} from 'react'
import { CartContext } from './CartContext';

export default function QuantityBtn({productInfo}) {
    const {cartItems, setCartItems} = useContext(CartContext);

    // check if the cart has the product associated with this button
    // findIndex() return -1 if the product is not in the cart or the corresponding index otherwise
    let productIdxInCart = cartItems.findIndex((item) => item.id === productInfo.id);

    let [quantity, setQuantity] = useState(productIdxInCart === -1 ? 0 : cartItems[productIdxInCart].quantity);

    const handleAdd = () => {
        if (productIdxInCart === -1) {
            setCartItems([...cartItems, 
                {
                    ...productInfo,
                    quantity: 1
                }
            ]);
        }
        else {
            let newCartItems = [...cartItems];
            newCartItems[productIdxInCart].quantity++;
            setCartItems(newCartItems);
        }
        setQuantity(quantity + 1);
    }

    const handleSubtract = () => {
        let newCartItems = [...cartItems];
        if (cartItems[productIdxInCart].quantity === 1) {
            newCartItems.splice(productIdxInCart, 1);
        }
        else {
            newCartItems[productIdxInCart].quantity--;
        }
        setCartItems(newCartItems);
        setQuantity(quantity - 1);
    }
    
    return (
        <div className="addToCart">
        {
            quantity === 0 ?
            <span className="addToCartBtn"onClick={handleAdd}>Add to shopping cart</span> :
            <div>
                <span className="subtractBtn" onClick={handleAdd}>+</span>
                {quantity}
                <span className="addBtn" onClick={handleSubtract}>-</span>
            </div>
        }
        </div>
    )
}
