// using "import ... from ..." can only import image in "src" dicectory
// if the images will only be used in a component, "import ... from ..." should be used
// if the images will be used in other components, the images should be put in "public" directory
//import apple from './apple.jpg';

import styles from './ProductList.module.css';
import { useState, useEffect, Fragment } from 'react'; // React Hook (released React 16.8)
import { Link } from 'react-router-dom';
import Title from './Title';
import QuantityBtn from './QuantityBtn';

export default function ProductList() {
    /*let productList = [
        {"id": 1, "name": "Apple", "price": 5, "image": "apple.jpg", "description": "fresh apple"},
        {"id": 2, "name": "Orange", "price": 3, "image": "orange.jpg", "description": "juicy orange"}, 
        {"id": 3, "name": "Mango", "price": 4, "image": "mango.jpg", "description": "suitable to make dessert"}, 
        {"id": 4, "name": "Watermelon", "price": 20, "image": "watermelon.jpg", "description": "summer essential"}, 
        {"id": 5, "name": "Blueberry", "price": 10, "image": "blueberry.jpg", "description": "good for eyes"}, 
        {"id": 6, "name": "White Carrot", "price": 5, "image": "white_carrot.jpg", "description": "suitable to make soup"}, 
    ];*/

    let [productList, setProductList] = useState([]);
    
    useEffect(() => { // callback is called "effect"
        // case 1: dependency array is not provided, callback will be invoked in every render
        // case 2: dependency array is empty, callback will only be invoked in the first rendering
        // case 3: dependency array has states, callback will be invoked in the first rendering or when any of the states changes

        fetch("https://hoyinleung.github.io/demoapi/react-basic-product.json") // async call
        .then(res => res.json())
        .then(data => setProductList(data))
    
    }, []); // array is called dependency array

    console.log(productList);
    return (
        <> {/* React Fragment will not be rendered (can also save the storage for the <div></div>???) */}
            <Title title="React Fruit Shop" />
            
            <div className="container">
            {
                productList.map(product => (  
                    <Fragment key={product.id}>
                        <div className={styles.containerItem}>
                            <Link to={'/product/' + product.id}>
                                <img src={process.env.PUBLIC_URL + '/images/' + product.image} alt={product.name}/>
                            </Link><br />

                            <div className="productName">
                                {product.name}  -  ${product.price} each
                            </div>

                            <QuantityBtn productInfo={product} />
                        </div>
                    </Fragment>
                ))
            }
            </div>
        </>
    );
}
